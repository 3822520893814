
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { BULK_BOOKING_CLIENT_TEMPLATE } from "@/app/infrastructures/misc/RolePermission";
import { BulkBookingController } from "@/app/ui/controllers/BulkBookingController";

@Options({
  components: {}
})
export default class ClientBookingDetail extends Vue {
  mounted() {
    this.fetchDetail();
  }

  fetchDetail() {
    BulkBookingController.getDetailClientBooking(Number(this.$route.params.id));
  }
  // Tabs
  tabs = [
    { name: "bulk-book", title: "Bulk Booking" },
    { name: "klien-booking", title: "Client Booking" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }

  get headerClient() {
    if (this.detailData.headerMapping.length === 0) {
      return "-";
    }
    return this.detailData.headerMapping
      .map((item: any) => {
        if (item) {
          return `<div class="px-2 py-1 text-gray-lp-300 font-semibold">${item.sourceHeader}</div>`;
        }
        return "-";
      })
      .join("<br>");
  }

  get headerGenesis() {
    if (this.detailData.headerMapping.length === 0) {
      return "-";
    }
    return this.detailData.headerMapping
      .map((item: any) => {
        if (item) {
          return `<div class="px-2 py-1 text-gray-lp-300 font-semibold">${item.genesisHeader}</div>`;
        }
        return "-";
      })
      .join("<br>");
  }
  get detailData() {
    return BulkBookingController.detailClientBookingData;
  }

  get createdAt() {
    return formatDate(this.detailData.createdAt);
  }
  get updatedAt() {
    return formatDate(this.detailData.updatedAt);
  }

  get isEditAble() {
    return checkRolePermission(BULK_BOOKING_CLIENT_TEMPLATE.EDIT);
  }
  goToEdit(item: any) {
    this.$router.push(`/shipment/bulks/klien-book/${item.id}/edit`);
  }

  get error() {
    return BulkBookingController.isError;
  }
  get errorCause() {
    return BulkBookingController.errorCause;
  }
  onTryAgain() {
    this.fetchDetail();
  }
}
