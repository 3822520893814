
import { formatDate } from "@/app/infrastructures/misc/Utils";
import { Options, Vue } from "vue-class-component";
import { BulkBookingController } from "@/app/ui/controllers/BulkBookingController";
import { sheetDefaultGenesis } from "./module/index";

@Options({
  components: {}
})
export default class ClientBookingEdit extends Vue {
  mounted() {
    this.fetchDetail();
  }

  fetchDetail() {
    BulkBookingController.getDetailClientBooking(Number(this.$route.params.id));
  }

  get dataDetail() {
    return BulkBookingController.detailClientBookingData;
  }

  // Tabs
  tabs = [
    { name: "bulk-book", title: "Bulk Booking" },
    { name: "edit-client-booking", title: "Client Booking" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }

  get tempExcel() {
    const data = this.dataDetail.headerMapping;
    return data;
  }

  sheetDefaultGenesis = sheetDefaultGenesis;

  currentIndex = 0;
  openMenu(index: number) {
    this.currentIndex = index;
    this.tempExcel[index].activitySelect = !this.tempExcel[index]
      .activitySelect;
  }

  closeMenu(index: number) {
    this.tempExcel[index].activitySelect = false;
  }

  onSelectSheet(name: string, value: string) {
    this.tempExcel[this.currentIndex].activitySelect = false;
    this.tempExcel[this.currentIndex].genesisHeader = value;
  }

  onAddHeader() {
    this.tempExcel.push({
      sourceHeader: "",
      genesisHeader: "",
      activitySelect: false
    });
  }
  get createdAt() {
    return formatDate(this.dataDetail.createdAt);
  }
  get updatedAt() {
    return formatDate(this.dataDetail.updatedAt);
  }

  get isSuccess() {
    return BulkBookingController.isModalSuccess;
  }

  successSubmit() {
    BulkBookingController.setModalSuccess(false);
    this.$router.push({
      name: "klien-book"
    });
  }
}
