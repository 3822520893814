
import { Options, Vue } from "vue-class-component";
import { PosClientController } from "@/app/ui/controllers/PosClientController";
import { BulkBookingController } from "@/app/ui/controllers/BulkBookingController";
import debounce from "lodash/debounce";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import XLSX from "xlsx";
import Dropdown from "primevue/dropdown";
import { sheetDefaultGenesis } from "./module/index";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

@Options({
  components: {
    UploadFile,
    Dropdown
  }
})
export default class ClientBookingDetail extends Vue {
  // Tabs
  tabs = [
    { name: "bulk-book", title: "Bulk Booking" },
    { name: "klien-booking", title: "Client Booking" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }

  clientCode = "";
  clientName = "";
  archiveFile = "";

  maxRow = 100;

  get form(): any {
    return BulkBookingController.form;
  }

  get isClearFile() {
    return BulkBookingController.isClearFile;
  }

  fetchPosClient = debounce((search: string) => {
    if (search.length > 2 || !search) {
      PosClientController.getPosClientList(search);
    }
  });

  get posClientList() {
    const list = [
      {
        id: 0,
        name: "Pilih client",
        status: "",
        isBanned: false,
        type: ""
      }
    ];
    PosClientController.posList.list.forEach((item: any) => {
      if (item.type !== "pos")
        list.push({
          id: item.id,
          name: item.name,
          status: item.status,
          isBanned: item.isBanned,
          type: item.type
        });
    });
    return list;
  }

  get isLoadingPosClient() {
    return PosClientController.isLoading;
  }

  setClientCode(item: any) {
    this.clientCode = item.id;
  }

  loadingUpload = false;
  importeds: any = [];
  tempExcel: any = [];
  extractFile() {
    this.loadingUpload = true;
    const files: any = this.archiveFile;
    this.importeds.splice(0, this.importeds.length);
    this.tempExcel.splice(0, this.tempExcel.length);
    const reader = new FileReader();
    if (files && files.name.split(".").pop() === "xlsx") {
      reader.onload = (e: any) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: Array<string> = XLSX.utils.sheet_to_json(ws, { header: 1 });

        for (const item of data[0]) {
          this.importeds.push(item);
        }

        for (const item of this.importeds) {
          this.tempExcel.push({
            sheetClient: item,
            sheetGenesis: "",
            activitySelect: false
          });
        }
      };
      reader.readAsBinaryString(files);
    }
    this.loadingUpload = false;
  }

  sheetDefaultGenesis = sheetDefaultGenesis;
  currentIndex = 0;
  openMenu(index: number) {
    this.currentIndex = index;
    this.tempExcel[index].activitySelect = !this.tempExcel[index]
      .activitySelect;
  }

  closeMenu(index: number) {
    this.tempExcel[index].activitySelect = false;
  }

  onSelectSheet(name: string, value: string) {
    this.tempExcel[this.currentIndex].activitySelect = false;
    this.tempExcel[this.currentIndex].sheetGenesis = value;
  }

  get submitDisable() {
    return !this.clientCode || !this.clientName;
  }

  get isSuccess() {
    return BulkBookingController.isModalSuccess;
  }

  submit() {
    BulkBookingController.createClientTemplateConfiguration({
      accountRefId: Number(this.clientCode),
      accountRefType: "client",
      headerMapping: this.tempExcel.map((item: any) => {
        return {
          genesisHeader: item.sheetGenesis,
          sourceHeader: item.sheetClient
        };
      })
    });
    GTMCommonEvent("client_template_save_clicked");
  }

  cancel() {
    this.directList();
  }

  successSubmit() {
    BulkBookingController.setModalSuccess(false);
    this.directList();
  }

  directList() {
    this.$router.push({
      name: "klien-book"
    });
  }
}
