export const sheetDefaultGenesis = [
  { name: "Pilih Config", value: "" },
  { name: "manual_stt_number", value: "manual_stt_number" },
  { name: "origin_district_name", value: "origin_district_name" },
  { name: "destination_district_name", value: "destination_district_name" },
  { name: "destination_postal_code", value: "destination_postal_code" },
  { name: "shipper_name", value: "shipper_name" },
  { name: "shipper_address", value: "shipper_address" },
  { name: "shipper_phone", value: "shipper_phone" },
  { name: "consignee_name", value: "consignee_name" },
  { name: "consignee_address", value: "consignee_address" },
  { name: "consignee_phone", value: "consignee_phone" },
  { name: "total_pieces", value: "total_pieces" },
  { name: "total_weight", value: "total_weight" },
  { name: "commodity_code", value: "commodity_code" },
  { name: "product_name", value: "product_name" },
  { name: "insurance_service_type", value: "insurance_service_type" },
  { name: "value_of_goods", value: "value_of_goods" },
  { name: "external_number", value: "external_number" },
  { name: "cod", value: "cod" },
  { name: "woodpacking", value: "woodpacking" }
];
